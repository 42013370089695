import React from "react"
import { Link, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import format from "date-fns/format"
import Img from "../components/Img"
import PostGrid, { PostGridItem } from "../components/styles/post-grid"
import { PostMetaTags } from "../components/meta-tags"
import { Banner } from "../components/banner"
import { H2, P } from "../components/page-elements"

const ContentContainer = tw.div`
  max-w-screen-md
  mx-auto
  px-page-gutter
  md:px-0
`

const Title = styled(H2)`
  ${tw`my-0`}
`

const PostMeta = tw.div`
  text-sm
  font-monospace
  my-2
`

const Time = tw.time`
  mx-1
`

const Categories = tw.ul`
  flex
`

const Category = tw.li`
  mr-2
  bg-base-color
  rounded
  py-2
  px-2
  mt-2
  shadow
`

const PAGE_CONSTANTS = {
  title: "Blog",
  header: "Blog",
  description:
    "We're a small business providing websites for other small businesses at an affordable price of $0 down, $150 a month.",
}

const Blog = function ({ data, pageContext, path }: any) {
  if (!data) return <p>Bummer! No Post found!</p>
  return (
    <>
      <Banner
        title={PAGE_CONSTANTS.title}
        header={PAGE_CONSTANTS.header}
        desctiption={PAGE_CONSTANTS.description}
      />
      <ContentContainer>
        {/* <Pagination
          currentPage={pageContext.currentPage}
          totalCount={data.allMdx.totalCount}
          pathPrefix="/blog/"
        /> */}
        <PostMetaTags
          post={{
            frontmatter: {
              slug: path,
              title: `Blog ${
                pageContext.currentPage
                  ? `- Page ${pageContext.currentPage}`
                  : ""
              }`,
            },
          }}
        />
        <PostGrid>
          {data.allMdx &&
            data.allMdx.edges.map(function ({ node: post }) {
              return (
                <PostGridItem key={post.id} to={post.fields.slug}>
                  {post.frontmatter.image &&
                    post.frontmatter.image.childImageSharp && (
                      <div>
                        <Img image={post.frontmatter.image} />
                        <PostMeta>
                          <Time dateTime={post.frontmatter.date}>
                            {format(
                              new Date(post.frontmatter.date),
                              "MMMM d, yyyy"
                            )}
                          </Time>
                          <Categories>
                            {post.frontmatter.category.map(cat => (
                              <Category key={cat}>{cat}</Category>
                            ))}
                          </Categories>
                        </PostMeta>
                      </div>
                    )}
                  <div>
                    <Title className="text-header">
                      {post.frontmatter.title}
                    </Title>
                    <P>{post.excerpt}</P>
                  </div>
                </PostGridItem>
              )
            })}
        </PostGrid>
        {/* <Pagination
          currentPage={pageContext.currentPage}
          totalCount={data.allMdx.totalCount}
          pathPrefix="/blog/"
        /> */}
      </ContentContainer>
    </>
  )
}

export default Blog

export const pageQuery = graphql`
  query blogPosts($skip: Int! = 0) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      filter: { fields: { collection: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 10
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          fields {
            collection
            slug
          }
          frontmatter {
            title
            date
            category
            image {
              ...ImageFields
            }
          }
        }
      }
    }
  }
`
