import { Link } from "gatsby"
import styled from "styled-components"
import tw from "twin.macro"

const PostGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const PostGridItem = styled(Link)`
  ${tw`
    grid
    gap-6
    py-8
    px-6
    rounded-lg
    bg-white
    border-l-4
    border-accent
    shadow
    cursor-pointer
    transition 
    duration-200 
    ease-in-out 
    hover:shadow-lg
    mb-6
  `}
  transform: 0.2s;
  grid-template-columns: 30% 1fr;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

export { PostGridItem }
export default PostGrid
